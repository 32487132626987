import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-update-comp',
    templateUrl: './menu-side-update-comp.component.html',
    styleUrls: ['./menu-side-update-comp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideUpdateCompComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public form: FormGroup;
    maxDate = new Date();

    constructor(protected fb: FormBuilder) {
        super();
        this.form = this.fb.group({
            status: [],
            start_date: [moment()],
        });
    }
    ngOnInit(): void {
        if (this.navData.data.comp) {
            this.form.get('status').patchValue(this.navData.data.comp.occasion_status == 'green');
            this.form.get('start_date').patchValue(this.navData.data.comp.done_at);
        }
        this.form
            .get('status')
            .valueChanges.pipe(
                takeUntil(this.destroy),
                filter((status) => !status)
            )
            .subscribe(() => this.form.get('start_date').patchValue(null));
    }
    public submit() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: this.form.value,
                type: this.navData.data.type,
            },
        });
    }
}
